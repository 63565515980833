.big-banner {
  background-image: url(https://cdnweb.devolutions.net/sysadminotaur/images/bckground-header.jpg);
  position: relative;
  height: 575px;
  background-size: cover;
  z-index: 2;
}

.header-maurice {
  position: absolute;
  left: 55%;
  width: 436px;
  top: 191px;
}

.devo-logo {
  height: 50px;
  position: absolute;
  left: 100px;
  top: 20px;
}

.sysadmin-logo-header {
  width: 365px;
  position: absolute;
  top: 175px;
}

.main-header-txt {
  color: var(--primary);
  width: 650px;
  position: absolute;
  top: 286px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1279px) {
  .header-maurice {
    display: none;
  }
}

.small-banner {
  background-image: url(https://cdnweb.devolutions.net/sysadminotaur/images/bckground-header.jpg);
  position: relative;
  height: 300px;
  background-size: cover;
  z-index: 2;
}

.sysadmin-logo-header-small {
  width: 365px;
  position: absolute;
  top: 105px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1279px) {
  .devo-logo {
    display: none;
  }

  .sysadmin-logo-header {
    left: 50%;
    transform: translateX(-50%);
    top: 120px;
  }

  .main-header-txt {
    left: 50%;
    width: calc(100% - 40px);
    transform: translateX(-50%);
    top: 231px;
  }
}
