.comic-display {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(25% - 20px);
  margin: 10px;

  img {
    width: 100%;
    max-width: none;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }

  a {
    text-decoration: none;
  }
}

.comics-container {
  display: flex;
  overflow: hidden;
  margin-top: 160px;
  position: relative;
  margin-bottom: 75px;
  margin-left: -10px;
  margin-right: -10px;
}

.comics-title {
  padding-top: 8px;
}

.comic-name {
  color: var(--colors-primary);
}

.comic-number {
  font-weight: 900;
  color: var(--colors-onBackground);
}

.latest-comic-container {
  position: relative;
  top: 485px;
  width: 590px;
}

.latest-strip-btn {
  position: absolute;
  top: -20px;
  z-index: 1;
  left: 40px;
}

.main-header-comic {
  width: 590px;
  left: 0;
  box-shadow: 0 3px 10px #0000004f;
  transition: transform 0.2s;
  max-width: 100%;

  &:hover {
    transform: scale(1.025);
  }
}

.comics-page-container {
  display: flex;
  width: auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: auto;
  max-width: unset;
}

.container-box {
  display: flex;
  justify-content: space-evenly;
  color: var(--colors-onSecondary);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.comic-page-display {
  width: 33%;
  flex: 0 0 33;
  padding: 0px 1% 25px 1%;
  box-sizing: border-box;

  a {
    color: var(--colors-primary);
    text-decoration: none;
    transition: all 0.25s ease-in-out;

    img {
      height: auto;
      max-width: 100%;
      transition: transform 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.comic-nav {
  display: flex;

  img {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
  }
}

.nav-icon {
  align-self: center;

  svg {
    color: var(--colors-primary);
    fill: currentColor;
    width: 40px;
    height: 40px;
  }
}

.nav-icon-default-disable {
  align-self: center;
  text-decoration: none;

  svg {
    color: var(--colors-primaryRgb);
    fill: currentColor;
    width: 24px;
    height: 24px;
  }
}

.nav-icon-disable {
  align-self: center;

  svg {
    color: var(--colors-primaryRgb);
    fill: currentColor;
    width: 40px;
    height: 40px;
  }
}

.nav-icon-default {
  align-self: center;
  text-decoration: none;

  svg {
    color: var(--colors-primary);
    fill: currentColor;
    width: 24px;
    height: 24px;
  }
}

.nav-menu {
  padding-top: 40px;
}

.testing {
  display: flex;
}

@media (max-width: 1279px) {
  .latest-comic-container {
    width: auto;
    margin: 0 20px;
  }

  .comics-container {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 240px;
    margin-bottom: 30px;
  }
  .comic-nav {
    a {
      display: none;
    }

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .comic-container {
    width: calc(100% - 40px);
  }
}

@media (max-width: 767px) {
  .comic-page-display {
    width: 100%;
  }

  .testing {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .comics-container {
    margin-top: 240px;

  }

  .comic-display {
    display: flex;
    font-weight: 500;
    flex: 0 0 48%;
    margin: 0;
  }
}
@media (max-width: 480px) {

  .comics-container {
    margin-top: 160px;

  }
}
@media (max-width: 320px) {

  .comics-container {
    margin-top: 120px;

  }
}
