.section {
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid var(--borderSurface);
  color: var(--onPrimary);
  padding: 75px 20px;
}

.title-header {
  font-family: var(--headerFont);
  font-size: 32px;
}

.section-width {
  max-width: 450px;
}

.section-width-default {
  max-width: 600px;
  a {
    color: var(--colors-primary);
    text-decoration: none;
    transition: all 0.25s ease-in-out;
  }
}

.img-section-width {
  img {
    width: auto;
    max-height: 275px;
  }
}

.section-grey {
  background-color: var(--section-backgound-grey);
}

.movie-short-container {
  display: flex;
  width: auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  max-width: unset;
  padding-bottom: 51%;

  iframe {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.movie-section {
  display: flex;
  justify-content: space-evenly;
  color: var(--colors-onSecondary);
  padding: 40px 20px;
}

.default-section {
  display: flex;
  justify-content: space-evenly;
  color: var(--colors-onSecondary);
  padding: 50px 20px
}

.section-width-default {
  max-width: 600px;
}

.team-description-focus {
  color: var(--colors-onSecondary);
  padding-top: 28px;
}

.close-btn {
  background-color: var(--section-backgound-grey);
  border: none;
  color: var(--colors-mainBlue);
  border-radius: 50px;
  padding: 5px;
  font-size: 16px;
  height: 35px;
  width: 35px;
  top: 15px;
  right: 15px !important;
  position: absolute;
  cursor: pointer;

  svg {
    color: var(--colors-mainBlue);
    fill: currentColor;
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: var(--colors-secondaryBlue);
  }
}

.nav-icon-default {
  align-self: center;
  text-decoration: none;
}

.section-noborder {
  display: flex;
  justify-content: space-evenly;
  color: var(--onPrimary);
  padding: 75px 20px;
}

.default-container-img {
  img {
    width: 100%;
    max-height: 275px;
  }
}

.team-role-focus {
  color: var(--colors-onSecondary);
  padding-top: 15px;
}

.team-display {
  width: 33%;
  flex: 0 0 33%;
  padding: 20px 1% 20px 1%;
  box-sizing: border-box;

  button {
    padding: 0;
    border: 0;
    background-color: transparent;
  }

  img {
    height: auto;
    max-width: 100%;
    border: 3px solid var(--onPrimary);
    box-shadow: 0 3px 6px #0003;

    &:hover {
      border: 3px solid var(--colors-primary);
      cursor: pointer;
    }
  }
}

.team-desc {
  text-align: left;
  padding-top: 10px;
  font-size: 16px;
}

.team-name {
  color: var(--colors-primary);
  font-weight: 600;
  font-size: 28px;
  text-transform: capitalize;
}
.team-role {
  color: var(--colors-onSecondary);
  font-family: var(--descFont);
}

.team-focus-panel {
  position: fixed;
  z-index: 1000;
  right: -100%;
  top: 0;
  overflow: auto;
  height: 100%;
  width: 900px;
  transition: right 0.5s;
  background-color: var(--primary);
  box-shadow: 0 8px 10px rgb(0 0 0 / 24%);
}

.active {
  right: 0;
}

.header-team-profil {
  height: 200px;
  background-color: var(--section-backgound-grey);
}

.profil-img {
  position: absolute;
  top: 77px;
  width: 325px;
  left: 50px;
}

.wallpaper-display {
  width: 33%;
  flex: 0 0 33;
  padding: 20px 1% 20px 1%;
  box-sizing: border-box;

  img {
    height: auto;
    max-width: 100%;
    border: 3px solid var(--onPrimary);
    box-shadow: 0 3px 6px #0003;

    &:hover {
      border: 3px solid var(--colors-primary);
      cursor: pointer;
    }
  }
}

.download {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.btn-left {
  background-color: var(--section-backgound-grey);
  border: 1px solid var(--colors-border);
  color: var(--onPrimary);
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 900;
  padding: 10px 25px;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 0.03em;
  border-bottom-right-radius: 0;
  text-decoration: none;
  border-top-right-radius: 0;
  outline: none;
  align-self: center;
}

.btn-right {
  background-color: var(--section-backgound-grey);
  border: 1px solid var(--colors-border);
  color: var(--onPrimary);
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 25px;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 0.03em;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  outline: none;
  align-self: center;
  font-family: var(--descFont);

  svg {
    width: 14px;
    height: 14px;
    margin-left: 8px;
    fill: currentColor;
  }
}

.dropdown {
  display: inlineBlock;
  position: relative;
  box-sizing: border-box;

  &:hover .dropdown-content {
    display: block;
  }
}

.dropdown-content {
  display: none;
  border: 1px solid var(--colors-border);
  position: absolute;
  bottom: 40px;
  background-color: var(--section-backgound-grey);
  min-width: 160px;
  z-index: 1;

  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: var(--colors-border);
    }
  }
}

@media (max-width: 1279px) {
  .img-section-width {
    display: none;
  }

  .movie-section {
    width: calc(100% - 40px);
  }

  .default-container-img {
    display: none;
  }

  .team-focus-panel {
    width: 100%;
    box-shadow: none;
  }

  .section-width,
  .section-width-default {
    max-width: 750px;
  }
}

@media (max-width: 767px) {
  .wallpaper-display {
    width: 100%;
  }

  .team-display {
    width: 100%;
    flex: 0 0 100%;
    padding: 20px 1% 20px 1%;
    height: 100%;
  }
}
