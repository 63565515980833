.nav-bar {
  display: flex;
}

.menu-display {
  justify-content: center;
  margin-left: auto;
  flex-wrap: wrap;
  display: flex;
  padding-top: 20px;
  font-family: var(--fonts-default);
}

.menu-tabs {
  border-bottom: 6px solid transparent;
  color: var(--colors-onSecondary);
  font-size: 16px;
  font-weight: 600;
  margin: 14px 14px 7px 14px;
  padding-bottom: 5px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: none;

  &:hover {
    color: var(--colors-primary);
  }

  &.active {
    color: var(--colors-primary);

    &::after {
      height: 6px;
      width: 100%;
      display: block;
      margin-top: 3px;
      background-color: var(--colors-primaryRgb);
      border-radius: 99px;
      content: '';
    }
  }
}

.sysadmin-logo-header-black {
  height: 50px;
}

.comic-menu-container {
  background-image: none;
  background-size: cover;
  box-shadow: 0 0 6px #0000002e;
  height: 80px;
  position: relative;
  z-index: 2;

  .nav-bar {
    align-items: center;
    display: flex;
    height: 100%;
  }
}

@media (max-width: 1279px) {
  .nav-bar {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    color: var(--primary);
    z-index: 2;

    .menu-display {
      margin-right: auto;
      flex-wrap: nowrap;
      padding: 10px;
    }
  }
  .sysadmin-logo-header-black {
    display: none;
  }
}

@media (max-width: 700px) {
  .menu-tabs {
    margin: 14px 8px 7px 8px;
    }
}

.black-menu {
  color: var(--onPrimary);
}
