body {
  background-color: var(--primary);
  padding: 0;
  margin: 0;
  font-family: proxima-nova, sans-serif;
}

@import url('https://use.typekit.net/upp6rus.css');

.site-width {
  transition: padding 400ms ease;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  width: 100%;
}
