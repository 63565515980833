:root,
body {
  --primary: #fff;
  --onPrimary: #000;
  --borderSurface: #e5e5e5;
  --colors-primary: #dd663e;
  --colors-primaryHover: #cc5e3a;
  --colors-onPrimary: #fff;
  --colors-mainBlue: #0068c3;
  --colors-secondaryBlue: #e3e8f3;
  --colors-border: #e5e5e5;
  --section-backgound-grey: #f5f5f5;
  --colors-onBackground: #424242;
  --colors-primaryRgb: rgb(221, 102, 62, 0.5);

  --headerFont: 'proxima-nova', sans-serif;
  --descFont: 'proxima-nova', sans-serif;
}
