.footer-container {
  font-size: 12px;
  text-align: center;
  padding: 20px;
}

.footer-links-container {
  text-align: center;
  padding: 10px 0;
}

.footer-links {
  padding: 0px 10px;
  text-decoration: none;
}
