.button-primary {
  background-color: var(--colors-primary);
  border: none;
  border-radius: 50px;
  color: var(--colors-onPrimary);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.03em;
  padding: 12px 30px;
  text-transform: uppercase;

  &:hover {
    background-color: var(--colors-primaryHover);
  }
}
